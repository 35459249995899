import {
  WhitelistResource,
  WhitelistPropertyResource,
  WhitelistIndexResource,
  BlocklistResource,
  BlocklistPropertyResource,
  BlocklistIndexResource,
  LanguagePropertyResource,
  LanguageIndexResource,
  ThemeResource,
  ThemePropertyResource,
  ThemeIndexResource,
  TicketResource,
  TicketPropertyResource,
  TicketIndexResource,
  TermsAndConditionsResource,
  TermsAndConditionsIndexResource,
  SettingsResource,
  SettingsSchema,
  BulkTicketResource,
  BulkTicketIndexResource,
  BulkTicketDeleteResource,
  BulkTicketCSVResource,
  BulkTicketPDFResource,
  BulkTicketEmailResource,
  BulkTicketRoomsResource,
  LoginTypesResource,
  LoginTypesIndexResource,
  MonitoringIndexResource,
  MonitoringExportResource,
  ProfileResource,
  SaveProfileResource,
  SessionStats,
  BandwidthStats,
  BandwidthStatsNow,
  SessionStatsNow,
  DisconnectDeviceResource,
  RevenueStats,
  TopSessionsStats,
  TopSessionsStatsCSV,
} from "./resources";

export default {
  indexWhitelist({ propertyId }) {
    return WhitelistIndexResource.get({
      propertyId,
    });
  },
  updateWhitelist({ id, propertyId, item }) {
    return WhitelistResource.update(
      {
        id,
        propertyId,
      },
      item
    );
  },
  saveWhitelist({ item, propertyId, whitelistId }) {
    return WhitelistPropertyResource.save(
      {
        propertyId,
        whitelistId,
      },
      item
    );
  },
  deleteWhitelist({ id, propertyId }) {
    return WhitelistResource.delete({
      id,
      propertyId,
    });
  },
  indexBlocklist({ propertyId }) {
    return BlocklistIndexResource.get({
      propertyId,
    });
  },
  updateBlocklist({ _id, propertyId, item }) {
    return BlocklistResource.update(
      {
        _id,
        propertyId,
      },
      item
    );
  },
  saveBlocklist({ item, propertyId, whitelistId }) {
    return BlocklistPropertyResource.save(
      {
        propertyId,
        whitelistId,
      },
      item
    );
  },
  deleteBlocklist({ _id, propertyId }) {
    return BlocklistResource.delete({
      _id,
      propertyId,
    });
  },
  indexLanguage({ propertyId }) {
    return LanguageIndexResource.get({
      propertyId,
    });
  },
  saveLanguage({ items = [], propertyId }) {
    return LanguagePropertyResource.update(
      {
        propertyId,
      },
      items
    );
  },
  indexTermsAndConditions({ propertyId }) {
    return TermsAndConditionsIndexResource.get({
      propertyId,
    });
  },
  updateTermsAndConditions({ items, propertyId }) {
    return TermsAndConditionsResource.update(
      {
        propertyId,
      },
      {
        items,
      }
    );
  },
  indexTheme({ propertyId }) {
    return ThemeIndexResource.get({
      propertyId,
    });
  },
  updateTheme({ id, item, propertyId }) {
    return ThemeResource.update(
      {
        id,
        propertyId,
      },
      item
    );
  },
  saveTheme({ item, propertyId, id }) {
    return ThemePropertyResource.save(
      {
        propertyId,
        id,
      },
      item
    );
  },
  deleteTheme({ id, propertyId }) {
    return ThemeResource.delete({
      id,
      propertyId,
    });
  },
  bulkDeleteTheme({ propertyId, items }) {
    return ThemePropertyResource.delete({ propertyId }, items);
  },
  getSettings({ propertyId }) {
    return SettingsResource.get({ propertyId });
  },
  getSchema({ propertyId }) {
    return SettingsSchema.get({ propertyId });
  },
  updateSettings({ items, propertyId }) {
    return SettingsResource.update({ propertyId }, { ...items });
  },
  bulkDeleteWhitelist({ propertyId, items }) {
    return WhitelistPropertyResource.delete({ propertyId }, items);
  },
  indexTicket({ propertyId }) {
    return TicketIndexResource.get({
      propertyId,
    });
  },
  updateTicket({ id, propertyId, item }) {
    return TicketResource.update(
      {
        id,
        propertyId,
      },
      item
    );
  },
  saveTicket({ item, propertyId, id }) {
    return TicketPropertyResource.save(
      {
        propertyId,
        id,
      },
      item
    );
  },
  deleteTicket({ id, propertyId }) {
    return TicketResource.delete({
      id,
      propertyId,
    });
  },
  bulkDeleteTicket({ propertyId, items }) {
    return TicketPropertyResource.delete({ propertyId }, items);
  },
  createBulkTicket({ propertyId, item }) {
    return BulkTicketResource.save({ propertyId }, item);
  },
  deleteBulkTicket({ ticketId, propertyId }) {
    return BulkTicketDeleteResource.delete({ ticketId, propertyId });
  },
  indexBulkTicket({ propertyId }) {
    return BulkTicketIndexResource.get({ propertyId });
  },
  getBulkTicketCSV({ ticketId, propertyId }) {
    return BulkTicketCSVResource.get({ ticketId, propertyId });
  },
  getBulkTicketPDF({ ticketId, propertyId }) {
    return BulkTicketPDFResource.get({ ticketId, propertyId });
  },
  postBulkTicketEmail({ ticketId, propertyId, email }) {
    return BulkTicketEmailResource.save({ ticketId, propertyId }, { email });
  },
  getRoomsBulkTicket({ propertyId }) {
    return BulkTicketRoomsResource.get({ propertyId });
  },
  indexLoginTypes({ propertyId }) {
    return LoginTypesIndexResource.get({ propertyID: propertyId });
  },
  saveLoginTypes({ propertyId, data }) {
    return LoginTypesResource.save({ propertyId }, data);
  },
  indexProfiles({
    propertyId,
    search,
    showEmails,
    showRooms,
    showAccessCodes,
    showAnonymous,
    showCards,
    showRewards,
    connected,
    limit,
    page,
    sortBy,
    sortDesc,
  }) {
    return MonitoringIndexResource.get({
      propertyId,
      search,
      showEmails,
      showRooms,
      showAccessCodes,
      showAnonymous,
      showCards,
      showRewards,
      connected,
      limit,
      page,
      sortBy,
      sortDesc,
    });
  },
  exportProfiles({
    propertyId,
    search,
    showEmails,
    showRooms,
    showAccessCodes,
    showAnonymous,
    showCards,
    showRewards,
    connected,
    sortBy,
    sortDesc,
  }) {
    return MonitoringExportResource.get({
      propertyId,
      search,
      showEmails,
      showRooms,
      showAccessCodes,
      showAnonymous,
      showCards,
      showRewards,
      connected,
      sortBy,
      sortDesc,
    });
  },
  updateProfile({ id, item, propertyId }) {
    return ProfileResource.update(
      {
        id,
        propertyId,
      },
      item
    );
  },
  deleteProfile({ id, propertyID }) {
    ProfileResource.delete({ id, propertyId: propertyID });
  },
  getProfile({ id, propertyId }) {
    return ProfileResource.get({ id, propertyId });
  },
  saveProfile({ item, propertyId, id }) {
    return SaveProfileResource.save(
      {
        propertyId,
        id,
      },
      item
    );
  },
  getBandwidthStats({
    propertyId,
    startDateTime,
    endDateTime,
    unit,
    binningType,
    valueType,
  }) {
    return BandwidthStats.save(
      {
        propertyId,
        sdt: startDateTime,
        edt: endDateTime,
        u: unit,
        bt: binningType,
        vt: valueType,
      },
      {}
    );
  },
  getSessionStats({ propertyId, startDateTime, endDateTime, binningType }) {
    return SessionStats.save(
      {
        propertyId,
        sdt: startDateTime,
        edt: endDateTime,
        bt: binningType,
      },
      {}
    );
  },
  getBandwidthStatsNow({ propertyId, vt }) {
    return BandwidthStatsNow.save(
      {
        propertyId,
        vt,
      },
      {}
    );
  },
  getSessionStatsNow({ propertyId }) {
    return SessionStatsNow.save(
      {
        propertyId,
      },
      {}
    );
  },
  sendDisconnectDevices({ propertyId, macs, disconnect }) {
    return DisconnectDeviceResource.save(
      {
        propertyId,
      },
      {
        macs,
        disconnect,
      }
    );
  },
  getRevenueStats({ propertyId, startDateTime, endDateTime, binningType }) {
    return RevenueStats.save(
      {
        propertyId,
        sdt: startDateTime,
        edt: endDateTime,
        bt: binningType,
      },
      {}
    );
  },
  getTopSessionsStats({
    propertyId,
    startDateTime,
    endDateTime,
    limit,
    skip,
    search,
  }) {
    return TopSessionsStats.save(
      {
        propertyId,
        sdt: startDateTime,
        edt: endDateTime,
        limit,
        skip,
        search,
      },
      {}
    );
  },
  getTopSessionsStatsCSV({ propertyId, startDateTime, endDateTime, search }) {
    return TopSessionsStatsCSV.get({
      propertyId,
      sdt: startDateTime,
      edt: endDateTime,
      search,
    });
  },
};
