export const API_ROOT =
  process.env.NODE_ENV === "production" ? "/api/cms/" : "/api/cms/";
export const API_ROOT_V2 =
  process.env.NODE_ENV === "production" ? "/api/cms/v2/" : "/api/cms/v2/";
export const API_ROOT_V3 =
  process.env.NODE_ENV === "production" ? "/api/cms/v3/" : "/api/cms/v3/";
export const BASE_URL = process.env.NODE_ENV === "production" ? "/cms/" : "";
export const API_PUBLIC_ROOT =
  process.env.NODE_ENV === "production" ? "/api/public/" : "/api/public/";
export const API_ADIS_ROOT_V1 =
  process.env.NODE_ENV === "production" ? "/api/adis/v1/" : "/api/adis/v1/";
